@import url('https://fonts.googleapis.com/css2?family=Inter:wght@800&family=Poppins:ital,wght@0,400;1,500&family=Raleway:ital,wght@1,100&display=swap');

html {
    scroll-behavior: smooth;
    --defaultthemecolor: #1691A5;
    --defaultwhitecolor: #ffffff;
    --defaultbluecolor: #2469B9;
}

.navbar-brand {
    padding-top: 0 !important;
}

.navbar-nav .nav-item.active .nav-link {
    color: var(--defaultthemecolor) !important;
    border-bottom: 2px solid var(--defaultthemecolor);
}

.navbar {
    background-color: var(--defaultwhitecolor);
    padding-top: 15px;
    padding-bottom: 15px;
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
    font-size: 12px;
}

.navbar-toggler-icon {
    width: 1rem;
    height: 1.2em;
}

.navbar-nav li a {
    transition: 1s ease;
}

.navbar-nav li a:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 1s ease;
    color: var(--defaultthemecolor) !important;
}

.nav-link {
    color: black;
    font-weight: bold;
    font-size: 0.9rem;
}

.login {
    width: 100px !important;
}

.logo img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.logo {
    height: 61px;
    /* height: 51px; */
    width: 11.5625rem
}

button.navbar-toggler {
    background-color: var(--defaultthemecolor) !important;
}

.navbar {
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
     */
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

/* Home */
.bgHome {
    /* background-image: url("../images//banner.png");
    background-size: cover;
    background-repeat: no-repeat; */
    height: calc(100vh - 30px);
    padding: 50px 0 60px;
    display: flex;
    align-items: center;
}


.homecontainer {
    padding: 60px;
}

.main {
    font-weight: 500;
}

.heading {
    font-size: 2.4rem;
    color: #1E284C;
    font-family: 'Inter', sans-serif;

}

.content {
    line-height: 2;
}

.appsbutton {
    height: 70px;
    cursor: pointer;
}

.buttonWrap {
    display: flex;

}

/* about */
.fontstyle {
    color: #1E284C;
    font-weight: bold;
    font-family: 'Inter', sans-serif;
}

.aboutcontent {
    padding: 46px;
}

/* excersice */
.postionParent {
    position: relative;
}

.seeALL {
    display: flex;
    justify-content: center;
}

.seeBUTton {
    margin: 45px;
    background-color: #1E284C !important;
}

.side {
    position: absolute;
    left: 91%;
    top: 169px;

    /* height: 500px; */
}

.side img {
    height: 100%;
    width: 100%;
}

.leftBar {
    position: absolute;
    position: absolute;
    top: 192px;
    left: -48px;

}

.leftBar img {
    height: 100%;
    width: 100%;
}

.ourexcercise {
    color: #1E284C;
    font-family: 'Inter', sans-serif;
    font-size: 3.5rem;
}

.bgColor {
    background-color: #F5F5F5;
}

/* three cotainer */
.color1 {
    background-color: #1E284C;
    padding: 60px;
    height: 100%;
}

.color2 {
    background-color: var(--defaultthemecolor);
    padding: 60px;
    height: 100%;
}

.content.ex {
    line-height: 1.8rem;
}

.less {
    padding-left: 0;
    padding-right: 0;
}

.less p {
    font-size: 1.4rem;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: var(--defaultwhitecolor);
}


/* download */
.play {
    height: 85px;
    cursor: pointer;
}

/* .play img{
    height: 100%;
    width:auto !important;
    object-fit: contain;
} */
.downloadContent {
    padding: 46px !important;
}

.appButton {
    display: flex;
}

/* contact */
/* .contactImage{
    background-image: url("../images//contact.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    border-radius: 10px;
} */
.contactImage img {
    border-radius: 10px;
}

.contactColor {
    color: #2E2E2E;
}

.inputsform {
    background-color: #F5F5F5;
    padding: 29px;
    border-radius: 10px;
    height: 100%;
}

.contactImage {
    height: 100%;
}

.button.widthset {
    width: auto;
}

/* footer */
.backgroundcolor {
    background-color: #1E284C;
    margin-top: 30px;
}

.footerimg {
    width: 9.875rem;
    padding: 16px;
    margin: 0 auto;
}

.footer_text {
    color: var(--defaultwhitecolor);
    text-align: center;
    padding: 10px;
    font-size: 0.8rem;
}

.footer_text a {
    color: var(--defaultwhitecolor);
    /* text-decoration: none; */
}

.footer {
    transition: 1s ease;
}

.footer:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 1s ease;
    color: var(--defaultthemecolor) !important;
    color: var(--defaultthemecolor);
}


.footer a.nav-link:hover {

    color: var(--defaultthemecolor) !important;
}

.nav_bottom {
    text-align: center;
}

/* comman css */
.spancolor {
    color: var(--defaultthemecolor);
    font-weight: bold;
}

.content {
    font-size: 0.9em;
}

img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.commanpadding {
    padding: 30px;
}

.flexi {
    display: flex;
    align-items: center;
}

.button {
    background-color: var(--defaultthemecolor);
    cursor: pointer;
    color: var(--defaultwhitecolor);
    width: 8.5625rem;
    padding: 11px;
    text-align: center;
    font-size: 0.8rem;
    border-radius: 5px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
}

.excesise_overflow {
    overflow: hidden;
    /* padding: 30px 0; */
}

.titlecolor {
    color: #1E284C;
    font-family: 'Inter', sans-serif;
}

.play img {
    transition: 1s ease;
}

.play img:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 1s ease;
}

.appsbutton img {
    transition: 1s ease;
}

.appsbutton img:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 1s ease;
}

.input ::placeholder {
    font-weight: 500;
    font-size: 0.9rem;
    opacity: 1;

}

.form-control {
    background-color: #E8E8E8;
}

hr {
    color: var(--defaultwhitecolor);
    margin: 0;
}

.phoneImg {
    position: relative;
    transition: all 1s ease;
    -o-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    top: 50px;
}

.phoneImg:hover {
    top: 0px;
}

.phoneImg:nth-child(1) {
    transform: rotate(0deg);
}

.imgdiv {
    position: relative;
    transition: all 1s ease;
    -o-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    top: 105px;
}

.imgdiv:hover {
    top: 0px;
}

.imgdiv:nth-child(1) {
    transform: rotate(0deg);
}

.imgdivUncle {
    position: relative;
    transition: all 1s ease;
    -o-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    top: 111px;
}

.imgdivUncle:hover {
    top: 0px;
}

.imgdivUncle:nth-child(1) {
    transform: rotate(0deg);
}

.imgdiv {
    height: 250px;
}

.imgdivUncle {
    height: 150px;
}

.imgdiv1 {
    height: 240px;
}

.excersice1 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    width: 350px;
}

/* .vidChild {
    position: absolute;
    top: 17px;
    left: 62px;
    height: 86%;
    width: 86%;
    overflow: hidden;
} */

.vidChild {
    height: 89%;
    left: 30px;
    overflow: hidden;
    position: absolute;
    top: 8px;
    width: 89%;
}

.videoshow {
    height: 100% !important;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.solidappmaker img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.solidappmaker {
    height: 35px;
    width: 84px;
    cursor: pointer;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fontstyle {
    opacity: 0;
    animation: fadeIn 3.5s ease-out forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fixedheight {
    min-height: 196px;
}

.termstop {
    top: 0;
    left: 93%;
    height: 50vh;
}

.termstop1 {
    position: absolute;
    bottom: 0;
    left: -3%;
    height: 50vh;
}

/* .bgwhite{
   background-image: url(../images//bgwhite.png);
   background-size: cover;
   background-repeat: no-repeat;
    height: 100%;
    width: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
} */

.parentPosition {
    position: relative;
}

.aboutImg {
    height: 70%;
}

.aboutsImage {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .bgwhite {
    background-image: url(../images//bgwhite.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

} */


.videoshow .ytp-chrome-top.ytp-show-cards-title {
    display: none !important;
}

.videoshow a.ytp-title-link.yt-uix-sessionlink {
    display: none !important;
    color: pink !important;
}

.videoshow .ytp-title-text {
    display: none !important;
    padding: 3px !important;
}

a.ytp-watermark.yt-uix-sessionlink.ytp-watermark-small {
    display: none;
}

.videoshow .html5-video-player {
    background-color: #1691A5 !important;
}

.ytp-chrome-top {
    display: none !important;
}

.react-player__title {
    display: none;
}

.react-player .react-player__title {
    display: none;
}

.react-player .react-player__title,
.react-player .react-player__title:hover {
    display: none;
}
.loadingLazy{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background-color: #5fa376; */
    color: black;
  }
  .loadingLazy >img{
    height: 200px;
    width: auto;
  }


  span.ant-collapse-header-text {
    font-weight: 600;
  }