@media (max-width: 991px) and (min-width :768px){
    .nav-link{
        font-size: 0.7rem;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }

}
@media (max-width: 425px){
    .vidChild {
        position: absolute;
        top: 18px;
        left: 38px;}
}
@media (max-width: 991px){
    .imgdiv{
        top: 0 !important;
    }
    .setvid{
        display: flex;
        justify-content: center;
    }
   
}

@media (max-width: 768px){
    .leftBar{
        top: 12px;
        left: -57px;
    }
    .aboutcontent{
        text-align: center;
    }
    .flexi{
        display: block;
    }
    .excesise_overflow{
        margin-top: 10px;
    }
    .side{
        display: none;
    }
    .termstop1{
        display: none;
    }
    .content{
        font-size: 0.8rem;
    }
    .heading {
        font-size: 1.9rem;}
    .homecontainer{
        padding: 10px !important;
    }
    .commanpadding{
        padding: 10px;
    }
    .bgHome{
        padding: 20px 0 20px;
    }
    .logo{
        width: 5.5625rem;
    }
    .aboutcontent{
        padding: 0px;

    padding-top: 10px;
    }
    .fontstyle{
        text-align: center;
    }
    .titlecolor{
        text-align: center;
    }
    .button{
        margin: 0 auto;
    }
    .excersice1{
        padding-top: 10px;
    }
    .downloadContent {
        padding: 0px !important;
        padding-top: 10px !important;
    }
    .footer .nav-link{
        font-size: 0.6rem;
    }
    .footpadding{
        padding-top: 10px !important;
    }
    .footerimg {
        width: 4.875rem;
        padding: 5px;
    }
        .footer_text{
            font-size: 0.7rem;
            padding: 10px;

        }
        hr{
            margin: 0;
        }
        .appButton{
            justify-content: center;
            display: flex;
        }
        .ourexcercise {
            font-size: 2.5rem;
        }
}

@media (min-width: 1600px){
 
    .leftBar{
        top: 270px;
        left: -49px;
    }
    .ourexcercise {
        font-size: 4.5rem !important;
    }
    .logo{
        height: 65px;
    }
    .nav-link {
        font-size: 1.5rem;
    }
    .homecontainer {
        padding: 32px;
    }
    .content{
        font-size: 1.2rem !important;
    }
    .main {
       
        font-weight: 500 !important;
    }
    .heading{
        font-size: 3.4rem;
    }
   
    .fontstyle{
        font-size: 3rem;
    }
    .ourexcercise{
        font-size: 3.5rem;
    }
    .titlecolor{
        font-size: 2.75rem;
    }
    .content.ex {
        line-height: 2.8rem;
    }
    .button {
        width: 11.5625rem;
        padding: 17px;
        font-size: 1.4rem;
    }
    .less p {
        font-size: 1.8rem;
    }
    .color1{
        padding: 68px
    }
    .color2{
        padding: 68px
    }
    .imgdiv {
        height: 250px;
    }
    .play {
        height: 100px;
    }
    .contactColor{
        font-size: 2.5rem;
    }
    .form-control{
        padding: 1.1rem 0.85rem;
    }
    
    .footer_text{
        font-size: 1.1rem;
    }
    /* .footpadding{
        padding-top: 13px !important;
    } */
}
@media screen and (max-width: 425px){
    .solidappmaker {
        height: 25px;
    }

}
@media (max-width: 768px) {
    .solidappmaker {
        display: block;
        margin: 10px auto;
    }
    .solidappmaker {
        height: 45px;
        width: 232px;
    }


}

@media (max-width: 500px){
    .bgHome{
        background-size: contain !important;
        height: calc(100vh - 152px) ;
    }
} 

@media (max-width: 375px){
    .bgHome{

        height: calc(100vh - 220px) ;
    }
}



